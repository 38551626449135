// riseup font family "Open sans" 
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

// specific styles for only riseup clients 
.riseUpMode {
    *:not(.katex *) {
        font-family: 'Open Sans', sans-serif !important;
    }

    header .help {
        display: none;
    }

    h2 {
        color: #263C66;
        font-weight: 600;
        font-weight: 18px;
    }

    .dataTableBottom .tableExpand>span {
        color: #263C66;
        font-size: 16px;
        font-weight: 600;
    }

    .circle_progress_bar {
        height: max-content;
        margin-top: 70px;

        .circle_progress_bar_label,
        .circle_progress_bar_legend,
        .circle_progress_bar_size,
        .circle_progress_bar_label_container {
            width: 160px;
            height: 160px;
            max-height: 160px;
            max-width: 160px;
            color: #263C66;
        }
    }

    .card_absolute_flex {
        justify-content: flex-start;

        .card_absolute_flex_left {
            width: 48px;
        }

        .card_absolute_text_container {
            width: auto;
            color: #263C66;

            div {
                font-weight: 600;
                color: #263C66 !important;
            }

            .absolute_title {
                font-weight: 400;
            }
        }
    }

    .pieChartWrapper {
        flex-direction: row;
        max-width: 620px;

        .pieLabels {
            margin-top: 0px;
            width: 50%;
            margin-left: 20px;
            justify-content: flex-start;
        }
    }

    .stat_header {
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 20px;

        .stat_header_title_container {
            text-align: left;
            max-width: 90% !important;
            margin-left: 7px;
            max-height: 56px;

            div {
                color: #263C66;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }

        }

        .stat_header_icon_container {
            margin-top: 0px;
            width: 20px;

            span {
                color: #8290B4;
                font-size: 24px;
                height: 30px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .App,
    .app-modal>div {
        max-width: 1300px;

    }

    .studentScreenRiseUp {
        padding: 0 24px;

        .studentScreenHeader {
            display: flex;
            align-items: center;
            margin-bottom: 57px;
            margin-top: 24px;

            .backButton {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 14px;
                margin: 0 14px;
                line-height: 20px;
                gap: 8px;
                border: none;
                background-color: transparent;

                span {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .studentName {
                margin-left: 8px;
                color: #32325D;
                font-size: 16px;
                font-weight: 700;
            }
        }

        .progressDataStudent {

            border: 1px solid #F3F3F7;
            border-radius: 8px;
            margin-bottom: 16px;

            .firstLine2 {
                padding: 0 24px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .skillsSection {
                cursor: default;
                border-top: 1px solid #F3F3F7;
            }

            .formationProgress {
                width: 100%;
            }

            .formationWrapper.disabledCourse {
                background-color: #F8F8F9;
                border-color: #F8F8F9;

                .skillName {
                    color: #898AA0 !important;
                }

                .skillLabels {
                    .firstLineLables {
                        background-color: #F8F8F9 !important;
                    }
                }

                .progressBar {
                    background-color: #898AA0 !important;
                }
            }


        }
    }



}