.positioningResult {
  padding-top: 25px;
  padding-bottom: 50px;
  position: relative;

  .tsrClose {
    position: absolute;
    right: 40px;
    top: 0;
    cursor: pointer;
  }

  .quizStats {
    .statCard {
      box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
    }
  }

  .skillSection {
    margin: 50px;

    .skillCard {
      .card {
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
      }
    }
  }

  .listingCorrection {
    padding: 0 50px;

    .correctioncards {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .listItem {
        width: 32%;
        height: 122px;
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 15px;
      }
    }
  }

  .correctionScreen {
    padding: 30px 50px;
    background-color: white;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100000000;
    justify-content: center;

    .hidden {
      display: block !important;
    }

    .InputCardGroup {
      .InputCard {
        &>div {
          height: auto;
        }
      }
    }

    &>.container {
      max-width: 1400px;
      display: flex;
      flex-direction: column;
      margin: 0 auto;
    }

    .header {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      position: absolute;
      left: 0;
      top: 0;

      div.close {
        cursor: pointer;
        margin: 15px;
        height: 40px;
      }

      .close {
        transition: 0.2s;
        color: #a1a1a1;

        &:hover {
          color: #686868;
        }
      }
    }

    .Footer {
      .ButtonWrapper {
        button {
          &:last-child {
            display: none;
          }
        }
      }
    }
  }
}