.SlidePanel {
  background-color: #fff;
  transition: transform 0.2s ease-in-out;
  position: absolute;
  box-sizing: border-box;
  width: calc(100vw + 20px);
  height: 100vh;
  padding: 20px;
  z-index: 9998;
  top: 0;
}

.SlidePanel.open {
  transform: translateX(-10px);
}

.SlidePanel.close {
  transform: translateX(-100%);
}

.StatFiltersSmall {
  display: none;
}

.StatFiltersSmall .openButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto 0;
  padding: 10px 20px;
  border: 0;
  border-radius: 8px;
  color: #8f9bba;
  background-color: #fff;
  filter: drop-shadow(0px 4.35821px 11.9851px rgba(0, 0, 0, 0.1));
}

.StatFiltersSmall svg,
.StatFiltersSmall span[class*='domText'] {
  font-weight: bold;
  color: #8f9bba;
}

.StatFiltersSmall .statFilters {
  justify-content: flex-start;
}

.StatFiltersSmall .statFilters > div:first-child {
  margin-left: 20px;
}

@media only screen and (max-width: 480px) {
  .StatFiltersSmall {
    display: initial;
  }
  .StatFiltersSmall .statFiltersWrapper {
    display: initial !important;
  }
  .statFiltersWrapper {
    display: none;
  }
}
