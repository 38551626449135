.progressData {
    position: relative;
    margin-top: 0px;
    padding: 20px;
    border-radius: 16px;
    box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);

    .progressHeader {
        display: flex;
        margin-bottom: 15px;

        .icon {
            color: #8290B4;
            font-size: 24px;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 30px;
        }

        .title {
            margin-left: 5px;

            .title_line1 {

                color: #263C66;
                font-size: 18px;
                font-weight: 600;
                line-height: 28px;
            }

            .title_line2 {
                color: #8290B4;
                font-size: 13px;
                font-weight: 400;
                line-height: 16px;
                text-transform: lowercase;

            }
        }
    }

    @media only screen and (max-width: 2000px) {
        .formationProgress {
            .col21 {
                margin-left: 10%;
            }
        }
    }

    @media only screen and (max-width: 1700px) {
        .formationProgress {
            .col21 {
                margin-left: 9%;
            }
        }
    }

    @media only screen and (max-width: 1500px) {
        .formationProgress {
            .col21 {
                margin-left: 6.5%;
            }
        }
    }

    @media only screen and (max-width: 1300px) {
        .formationProgress {
            .col21 {
                margin-left: 5%;
            }
        }
    }

    @media only screen and (max-width: 1200px) {
        .formationProgress {
            .col21 {
                margin-left: 4%;
            }
        }
    }

    @media only screen and (max-width: 1000px) {
        .formationProgress {
            .col21 {
                margin-left: 2%;
            }
        }
    }

    @media only screen and (max-width: 800px) {
        .formationProgress {
            .col21 {
                margin-left: 4%;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        .formationProgress {
            .col21 {
                margin-left: 10%;
            }
        }
    }

    @media only screen and (max-width: 400px) {
        .formationProgress {
            .col21 {
                margin-left: 10%;
            }
        }
    }
}