.studentScreenHub {
  .mySkills .radarScreen .radarWrapper {
    background-color: #fff;
  }

  .adaptiveNavBar {
    padding: 0 65px;
    margin-top: 10px;

    .container {
      display: block;
    }

    @media (max-width: 1000px) {
      margin-top: 10px;
    }

    .clickable {
      cursor: pointer;

      .badge-wrapper {
        max-width: 260px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
      }
    }

    .navigationButtons {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 40px 0 0;

      &>*:first-child {
        margin-right: 10px;
      }

      .navButtonWrapper {
        margin: 0 10px;
      }
    }
  }

  .adaptivePathScreen {
    margin-top: -34px;
    padding-bottom: 50px;
  }

  .otsLearningPath .learningPathV2 .downloadBtnLine button {
    background-color: #6951e0 !important;
  }

  .onlyPosCard {
    .actionCardWrapperV2 {
      cursor: default !important;
    }
  }

  .adaptiveSkills {
    margin-top: -30px;
    padding-bottom: 50px;

    .skillCard {
      .card {
        box-shadow: 0px 0px 60px 0px rgba(0, 0, 0, 0.06);
      }
    }
  }

  .adaptiveContentScreen {
    position: relative;
    padding-top: 18px;

    .adaptiveContent {
      margin-top: 0px;
      min-height: auto;
    }

    .tsrClose {
      position: absolute;
      right: 40px;
      top: 0;
      cursor: pointer;
      z-index: 2;
    }
  }
}