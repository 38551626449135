.riseUpMode {
    .ActivityScreenLock {
        .firstLine>div {
            margin: 0 24px 24px 0;
        }

        .firstLine>div:last-child {
            width: 80%;
            margin-right: 0;

            .large_container {
                max-width: 100%;
            }
        }

        .small_container {
            max-width: 267px;
        }

        @media only screen and (max-width: 1040px) {
            .small_container {
                max-width: 100%;
            }

            .firstLine {
                gap: 24px;

                .componentAbsoluteNumber,
                .componentPercentage {
                    width: 46% !important;
                }
            }

            .firstLine>div:last-child {
                width: 100%;
            }

            .firstLine>div {
                margin: 0 0px 24px 0;
                margin-right: 0;
            }
        }

        @media only screen and (max-width: 700px) {
            .small_container {
                max-width: 100%;
            }

            .firstLine {
                gap: 0;

                .componentAbsoluteNumber,
                .componentPercentage {
                    width: 100% !important;
                    margin-right: 0;
                }
            }


        }

        .large_container {
            div.textGreyStats {
                color: #263C66;
                font-size: 16px;
                font-weight: 600;
                line-height: 28px;
            }
        }

    }

}