.statFiltersWrapper {
  margin-bottom: 30px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.statFilters {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}
.statFilters > div {
  margin-left: 50px;
}
.statFilters > div:first-child {
  margin-left: 0px;
}
.statFiltersWrapper .step2 {
  max-width: min-content;
  padding: 0 20px 20px;
}

@media (max-width: 1090px) {
  .statFilters {
    flex-wrap: wrap;
  }
  .statFilters > div {
    margin-left: 20px;
  }
  .statFiltersWrapper .step2 {
    max-width: max-content;
  }
}
