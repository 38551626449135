@import '../../../styles/screens.scss';

// ---- ENGAGEMENT SCREEN HUB ---- //
.EngagementScreenHub {
  .TitleContainer h2 {
    @include sm {
      margin: 0 auto;
    }
  }

  .componentAbsoluteNumber {
    margin-bottom: 30px;
  }

  .componentsPercentage {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @include xs {
      flex-direction: column;
    }
    .percentage {
      width: 250px;
      margin-bottom: 20px;
      margin-right: 20px;
      @include xs {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 950px) {
  .EngagementScreenHub {
    .componentAbsoluteNumber {
      display: flex;
      justify-content: center;
      .small_container {
        max-width: 300px;
        width: 250px;
        .card_absolute_text_container {
          word-break: keep-all;
        }
      }
    }
    .componentsPercentage {
      width: 100%;
      .percentage {
        width: 48%;
        margin-bottom: 20px;
        margin-right: 2%;

        .medium_container {
          margin: 0 auto;
          max-width: 80%;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .EngagementScreenHub {
    .componentsPercentage {
      width: 100%;
      .percentage {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 20px;
        .medium_container {
          margin: 0 auto;
          max-width: 80%;
        }
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .EngagementScreenHub {
    .componentsPercentage {
      width: 250px;
      .percentage {
        width: 100%;
        margin-bottom: 20px;
        margin-right: 20px;
        .medium_container {
          margin: 0 auto;
          max-width: 80%;
        }
      }
    }
  }
}

// ----  ENGAGEMENT SCREEN HUB ---- //
