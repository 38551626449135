// ----- MEDIA QUERIES ---- //

@mixin xs {
  @media (max-width: 576px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 768px) {
    @content;
  }
}

@mixin md {
  @media (max-width: 992px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: 1200px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: 1400px) {
    @content;
  }
}
// ----- EO MEDIA QUERIES ---- //

