.trailNavigation {
  background: #fff;
  box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.06);
  width: 100%;
  display: flex;
  margin-top: -15px;
  margin-bottom: 22px;
  box-sizing: border-box;
  padding: 20px 35px;
  .userTitle {
    color: #7f7f7f;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    white-space: nowrap;
  }
  .user {
    display: flex;
    align-items: center;
    max-width: 50%;

    height: max-content;
    .backButton {
      width: 24px;
      height: 24px;
      min-width: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      cursor: pointer;
      margin-right: 10px;
    }
    .userName {
      color: #7f7f7f;
      font-size: 14px;
      font-weight: 400;
      border-radius: 5px;
      border: 1px solid #7f7f7f;
      padding: 2px 11px;
      margin-right: 10px;
      white-space: nowrap;
      max-width: 75%;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
  .rightNav {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: max-content;
    min-height: 24px;
    .infoLineWrapper {
      .otherInfos {
        display: flex;
        line-height: 18px;
        .trailArrow {
          font-size: 16px;
          margin-right: 10px;
          margin-left: 10px;
          margin-bottom: -5px;
        }
      }
    }
  }
}
