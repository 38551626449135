@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
.App,
.app-modal > div {
  background-color: white;
  max-width: 1100px;
  margin: 0 auto;
  padding: 10px 40px;
  overflow-y: scroll;
}
.app-modal > div {
  padding: 0px;
  box-sizing: border-box;
  overflow-y: scroll;
}

.App,
.app-modal > div::-webkit-scrollbar-thumb {
  background-color: white;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 640px) {
  .App {
    padding: 10px;
  }
  .App,
  .app-modal > div {
    padding: 20px;

    box-sizing: border-box;
  }
}

.HomeScreen {
  opacity: 0;
}

.fadeIn {
  transition: 1s;
  animation: showMe 1s 0.2s forwards;
  opacity: 0;
}

@keyframes showMe {
  to {
    opacity: 1;
  }
}

@media only screen and (max-width: 480px) {
  .App {
    padding-bottom: 75px;
  }
  .statLoaderWrapper .statLogo {
    width: 150px;
    height: 150px;
  }
  .statLoaderWrapper .svgShadow {
    width: 40px;
    margin-top: -33px;
  }
}
