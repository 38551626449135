@import './mediaqueries.scss';
@import './variables.scss';
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@500&display=swap');

// -------- HOME SCREEN --------- //

.back-button {
  height: 48px;
  width: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 6px 5px rgba(0, 170, 206, 0.1);
}

// -------- HOME SCREEN -------- //

// -------- EO HOME SCREEN -------- //
