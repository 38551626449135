/* 
* ProgressScreenLock
*/
.ProgressScreenLock {
  .firstLine {
    display: flex;
  }

  .secondLine {
    display: flex;
    margin-bottom: 50px;
  }

  .graphes {
    .firstLine>div:first-child {
      width: 25%;
    }

    .firstLine>div {
      margin: 0 25px 50px 0;
      width: 35%;
    }

    .firstLine>div:last-child {
      margin: 0 0px 50px 0;
    }

    .firstLine>div.carret .stat_header .stat_header_title_container {
      max-width: 180px;
    }

    .firstLine>div.carret>div {
      box-sizing: border-box;
      max-width: 100%;
      height: fit-content;
      min-height: 350px;
    }

    .firstLine>div>div:not(:last-child) {
      margin-bottom: 50px;
    }

    .firstLine>div:last-child {
      margin: 0 0 50px 0;
    }

    .secondLine>div:first-child {
      width: 25%;
    }

    .secondLine .medium_container {
      box-sizing: border-box;
    }

    .secondLine>div:not(:last-child) {
      margin-right: 25px;
    }

    .secondLine>div {
      margin-bottom: 50px;
    }

    .secondLine>div:not(:first-child),
    .secondLine>div:not(:first-child)>div {
      height: fit-content;
      min-height: 300px;
    }

    .secondLine>div:not(:first-child)>div {
      width: 385px;
    }

    .firstLine .pieChartWrapper .circle_progress_bar,
    .secondLine .pieChartWrapper .circle_progress_bar {
      transform: scale(1.3);
      margin-top: 70px;
    }
  }

  .card_absolute_text_container {
    word-break: normal !important;
  }

  .small_container,
  .medium_container {
    max-width: 270px;
  }

  .stat_header .stat_header_title_container {
    max-width: 200px !important;
  }

  @media only screen and (max-width: 950px) {
    .firstLine {
      flex-wrap: wrap;
      justify-content: center;
    }

    .graphes .firstLine>div,
    .graphes .firstLine>div:first-child {
      width: 100%;
      margin: 0 10px 30px 10px;
    }

    .graphes .firstLine>div>div {
      width: 100%;
    }

    .graphes .firstLine .col {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }

    .graphes .firstLine .col .componentAbsoluteNumber {
      width: 48%;
    }

    .graphes .firstLine .col .componentAbsoluteNumber:first-child {
      margin-right: 4%;
    }

    .graphes .firstLine>div>div>.small_container,
    .graphes .firstLine>div>div>.medium_container {
      max-width: 100%;
    }

    .pieChartWrapper {
      margin: 0 auto;
    }

    .secondLine {
      justify-content: center;
      flex-wrap: wrap;
    }

    .graphes .secondLine>div,
    .graphes .secondLine>.small_container {
      width: 100% !important;
    }
  }
}

/* 
* EngagementScreenLock
*/
.EngagementScreenLock {
  .firstLine {
    display: flex;
    justify-content: space-between;
  }

  .graphes .firstLine>div {
    width: 25%;
    margin: 0 25px 50px 0;
  }

  .graphes .firstLine>div:last-child {
    margin: 0 0 20px 0;
  }

  .graphes .secondLine>div {
    width: 100%;
    margin: 0 25px 50px 0;
  }

  .graphes .secondLine>div:last-child {
    margin: 0 0 50px 0;
  }

  .graphes .secondLine>div :first-child .stat_header .stat_header_icon_container>span {
    color: #32d296;
  }

  .card_absolute_text_container {
    word-break: normal !important;
  }

  .graphes .secondLine {
    justify-content: space-between;
    display: flex;
  }

  .small_container,
  .medium_container {
    max-width: 270px;
  }

  @media only screen and (max-width: 1040px) {

    .graphes .firstLine>div,
    .graphes .secondLine>div {
      width: inherit;
    }

    .graphes .firstLine,
    .graphes .secondLine {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    .graphes .firstLine .componentAbsoluteNumber {
      width: 48%;
      margin: 0px;
      margin-bottom: 20px;
    }

    .graphes .firstLine .componentAbsoluteNumber:nth-child(odd) {
      margin-right: 4%;
    }

    .graphes .secondLine .percentage {
      width: 100%;
      margin: 0px;
      margin-bottom: 20px;
    }

    .graphes .secondLine .percentage>div {
      max-width: 100%;
    }

    .graphes .secondLine>div {
      margin: 0 10px 30px 10px;
    }
  }
}

/* 
* ActivityScreenLock
*/
@import '../../styles/screens.scss';

.ActivityScreenLock {
  .firstLine {
    display: flex;
    justify-content: space-between;
  }

  .firstLine>div {
    width: 25%;
    margin: 0 25px 50px 0;
  }

  .firstLine>div:last-child {
    width: 75%;
    margin: 0 0 50px 0;
  }

  .firstLine .medium_container {
    height: 270px;
    max-width: 220px;
  }

  .small-vw {
    display: none;
  }

  .mobileUsage {
    .pieContainer {
      margin-top: -10px;
    }
  }

  @media only screen and (max-width: 1040px) {
    .lg-vw {
      display: none;
    }

    .small-vw {
      display: initial;
    }

    .firstLine {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    .firstLine .componentAbsoluteNumber,
    .firstLine .componentPercentage {
      width: 48% !important;
      margin: 0px;
      margin-bottom: 20px;
    }

    .firstLine .componentAbsoluteNumber:nth-child(odd),
    .firstLine .componentPercentage:nth-child(odd) {
      margin-right: 4%;
    }

    .firstLine>div>div {
      max-width: 100%;
    }

    .firstLine>div:last-child {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 500px) {
  .ActivityScreenLock .firstLine .componentPercentage {
    width: 90% !important;
  }

  .ActivityScreenLock .firstLine .componentPercentage:nth-child(odd) {
    margin-right: 0;
  }

  .ActivityScreenLock .firstLine .medium_container {
    max-width: 90%;
  }
}

/* 
* StudentScreen
*/
.StudentScreen {
  padding: 30px;

  .errorStudentScreen {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    min-height: 400px
  }

  .progressTrail {
    .tooltip-box {
      white-space: nowrap
    }
  }

}