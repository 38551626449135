.underlinedButtonWrapper {
  cursor: pointer;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  .active {
    border: none;
    border-bottom: 2px solid;
    padding-bottom: 10px;
    background-color: transparent;
    cursor: pointer;
  }
  .inactive {
    border: none;
    padding-bottom: 10px;
    background-color: transparent;
    cursor: pointer;
  }
  .otsUnderleinedButtonColor {
    color: #bf4de8;
    border-color: #bf4de8;
  }
}
