.dataTableBottom {
  position: relative;
  height: min-content;
}
.dataTableBottom .tableExpand {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.dataTableBottom .tableExpand > span:first-child {
  font-weight: 500;
  font-size: 18px;
  line-height: 29px;
  margin-right: 10px;
}

.dataTableBottom .showMoreIcon {
  transition: 0.3s ease;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dataTableBottom .displayTable {
  transition: 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  height: max-content;
  position: relative;
}
.dataTableBottom .displayTable .dataTableWrapper {
  width: 100%;
}
.dataTableBottom .hideTable {
  display: none;
  height: 0;
  transition: 0.3s ease;
  overflow: hidden;
}

.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.dataTableBottom .errorDatatable {
  display: flex;
  align-items: 'center';
  justify-content: center;
  flex-direction: column;
  text-align: center;
  color: #8f9bba;
}
.dataTableBottom .errorDatatable > div {
  margin: 0 auto;
}
