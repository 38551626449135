/*
 * Errors
 */

/* shared */
.errorContainer {
  height: 100%;
  width: 100%;
  text-align: center;
  display: flex;
}
.errorBackground {
  position: fixed;
  width: 2500px;
  bottom: -60%;
  left: calc(50% - 1250px);
  z-index: -1;
  font-size: 1250px;
  font-weight: bold;
  color: rgba(218, 218, 218, 0.22);
}
.errorContent {
  margin: 0 auto;
  margin-top: auto;
  padding-bottom: 50px;
}
/* not found */
.notFoundContent {
  margin-bottom: -80px;
}
.notFoundContent button {
  z-index: 2;
}
.notFoundContainer {
  height: 670px;
  width: 800px;
  overflow: hidden;
  position: relative;
}
.notFoundTitle {
  font-size: 48px;
  font-weight: bold;
  position: absolute;
  width: 200px;
  transform: rotate(17.5deg);
  top: 211px;
  left: 192px;
}
/* access denied */
.accessDeniedContainer {
  height: 625px;
  width: 1000px;
  overflow: hidden;
}
.accessDeniedTitle {
  margin-bottom: -50px;
  font-size: 48px;
  font-weight: bold;
}
/* internal error */
.internalErrorContainer {
  height: 750px;
  width: 800px;
  overflow: hidden;
  margin-bottom: -20px;
}
.internalErrorTitle {
  margin-bottom: -175px;
  font-size: 48px;
  font-weight: bold;
}
/* Error */
.crashErrorBackground {
  position: fixed;
  width: 2500px;
  bottom: -35%;
  left: calc(50% - 1250px);
  z-index: -1;
  font-size: 800px;
  font-weight: bold;
  color: rgba(218, 218, 218, 0.22);
}
.crashErrorContainer {
  height: 700px;
  width: 700px;
  overflow: hidden;
  margin-bottom: -20px;
}
.crashErrorTitle {
  margin-bottom: -210px;
  font-size: 48px;
  font-weight: bold;
  text-align: center;
  padding-right: 170px;
}
.logoDomoscio {
  width: 800px;
}
.fallbackContainer {
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 400px);
}
