@import '../../../styles/screens.scss';

.ActivityScreenHub {
  @include sm {
    padding-left: 15px;
  }
  .TitleContainer h2 {
    @include sm {
      margin: 0 auto;
    }
  }
}

// ---- ACTIVITY SCREEN HUB ---- //
.activityContainerHub {
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .card_absolute_text_container {
    word-break: normal;
  }
  @include md {
    flex-direction: column;
  }

  // Card Absolute Number
  .componentAbsoluteNumber {
    width: 25%;
    margin-bottom: 30px;
    @include md {
      width: 50%;
    }
    @include sm {
      width: 95%;
    }
  }

  // Card EvolutionGRaph
  .componentEvolutionGraph {
    width: 75%;
    @include lg {
      width: 72%;
    }
    @include md {
      width: 90%;
    }
    @include sm {
      width: 95%;
    }
  }
}
// ----  EO ACTIVITY SCREEN HUB ---- //
