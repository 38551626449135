.learningProgramShow {
  padding: 0 65px;
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 74px;
    .title {
      color: #505050;
      font-family: DM Sans;
      font-size: 16px;
      font-weight: 700;
    }
  }

  .themeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 10px;
    a {
      width: 320px;
      max-width: 320px;
      height: 250px;
      margin-bottom: 66px;
      transition-duration: 200ms;
    }
    .emptyCard {
      width: 320px;
    }
  }
}
@media (max-width: 900px) {
  .heading {
    flex-direction: column;
  }
}
