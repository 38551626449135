header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.step1 > div:first-child {
  margin-left: 0;
}
.step1 {
  max-width: min-content;
  padding: 0 10px;
}

header.HomeNavSmall {
  display: none;
  background-color: #fff;
  overflow: hidden;
  position: fixed;
  height: 60px;
  left: 0;
  bottom: 0px;
  width: 100%;
  left: 0;
  z-index: 9998;
  padding-bottom: 20px;
  border-radius: 10px 10px 0 0;
  box-shadow: 0px 1px 18px rgba(0, 0, 0, 0.25);
}

header.HomeNavSmall nav,
header.HomeNavSmall nav > div {
  width: 100%;
  max-width: 100%;
}
header.HomeNavSmall nav > div {
  padding: 0 50px;
  justify-content: space-between;
}

.HomeNavSmallLink .link {
  color: #103f4b;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.HomeNavSmallLink .link {
  color: #103f4b;
}
.HomeNavSmallLink svg {
  font-size: 30px;
}
.HomeNavSmallLink .link span {
  margin-top: 10px;
  font-size: 12px;
}

@media only screen and (max-width: 480px) {
  header.HomeNavSmall {
    display: initial;
  }
  header:not(.HomeNavSmall) {
    display: none;
  }
}
