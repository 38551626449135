.exportButtonContainer {
  position: relative;
  .exportButton {
    border: none;
    outline: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4px;
    margin-bottom: 4px;
    color: #fff;
    cursor: pointer;
    transition: 0.2s;
    &:disabled {
      background-color: #cccccc;
      color: #666666;
      cursor: not-allowed;
    }
  }
}
.activeButton:hover {
  opacity: 0.8;
}

.disabledWrapper {
  &:hover .exportTooltipWrapper {
    visibility: visible;
    opacity: 1;
    transition-delay: 0.2s;
  }
  .exportTooltipWrapper {
    position: absolute;
    top: 50px;
    max-width: 230px;
    right: -10px;
    font-size: 14px;
    background-color: #8f9bba;
    color: #fff;
    border-radius: 30px;
    padding: 10px 15px;
    cursor: default;
    width: max-content;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    z-index: 2000;
    transition: visibility 0.4s linear, opacity 0.4s linear;
    &::after {
      content: '';
      top: -8px;
      right: 20px;
      position: absolute;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #8f9bba;
    }
  }
}

.exportModal {
  position: relative;
  background-color: white;
  padding: 32px 64px;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 0 20 99px rgba(0, 0, 0, 0.5);

  h4 {
    color: #263c66;
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 40px;
    margin-top: 0;
  }
  p {
    color: #8290b4;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 48px;
  }
  .navButtonWrapper {
    &:hover {
      opacity: 0.8;
    }
  }
  .closeIcon {
    position: absolute;
    top: 32px;
    right: 32px;
    font-size: 24px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
}

/* animation */

@keyframes anvil {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(200px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
.popup-content {
  -webkit-animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  animation: anvil 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
}

.custom-content {
  margin: 0 auto !important;
}
