// ---- TITLESCREEN COMPONENT ----- //
.TitleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  .BorderRadiusCircle {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 5px;
  }
}
// ---- EO TITLE ----- //
