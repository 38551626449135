.App .loaderWrapper {
  margin-top: 200px;
}

.App .filterMenu .showMenu {
  max-height: 400px;
}
.App .filterMenuWrapper .titleTooltipWrapper {
  z-index: 2000;
}

#root .app-modal + .App {
  display: none;
}

header {
  position: relative;
}
header .help {
  position: absolute;
  right: 20px;
  top: 25px;
  cursor: pointer;
}

/* Tailwind base */

.opacity-75 {
  opacity: 0.75;
}
.bg-gray-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity));
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.absolute {
  position: absolute;
}
.inset-0 {
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}
.w-full {
  width: 100%;
}
.w-full {
  width: 100% !important;
}
.h-screen {
  height: 100vh !important;
}
.overflow-hidden {
  overflow: hidden;
}
.h-screen {
  height: 100vh;
}
.z-50 {
  z-index: 10;
}
.z-10 {
  z-index: 10;
}
.text-center {
  text-align: center !important;
}
.pt-4 {
  padding-top: 1rem !important;
}
.px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.min-h-screen {
  min-height: 100vh !important;
}
.justify-center {
  justify-content: center !important;
}
.items-end {
  align-items: flex-end !important;
}
.flex {
  display: flex !important;
}
.flex-col {
  flex-direction: column;
}
.hidden {
  display: none !important;
}
.bg-white {
  background-color: #fff !important;
}
@media (max-width: 600px) {
  .col2 .radarWrapperLegacy .radarTitleWrapper {
    width: auto;
    text-align: center;
  }
}

#root .studentScreenSize {
  padding: 0;
  max-width: 1200px;
}
#root .noMargin {
  margin-top: 0;
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
}
.popup-overlay {
  background: rgba(0, 0, 0, 0.2);
  z-index: 10000000 !important;
  border-radius: 5px;
}
