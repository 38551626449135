.exportDownload {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
  height: 90vh;
  p {
    max-width: 700px;
    text-align: center;
    line-height: 20px;
  }
  button {
    border-radius: 33px;
    border: none;
    padding: 10px 40px;
    margin-top: 20px;
    color: white;
    background-color: #3182ce;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  a {
    text-decoration: underline;
    cursor: pointer;
    color: black;
  }
}
