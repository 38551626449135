/* info popup */

.infoPopup-content {
  margin: 0 auto !important;
  -webkit-animation: anvil2 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
  animation: anvil2 0.3s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards !important;
  .statusModal {
    max-width: 590px;
  }
  .statusModal {
    p b {
      color: rgb(74, 74, 74);
    }
    p li {
      margin: 10px auto;
    }
  }
}

@keyframes anvil2 {
  0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  1% {
    transform: scale(0.96) translateY(0px);
    opacity: 0;
    box-shadow: 0 0 0 rgba(241, 241, 241, 0);
  }
  100% {
    transform: scale(1) translateY(150px);
    opacity: 1;
    box-shadow: 0 0 500px rgba(241, 241, 241, 0);
  }
}
