.riseUpMode {
    .graphes {

        .firstLine .pieChartWrapper .circle_progress_bar,
        .secondLine .pieChartWrapper .circle_progress_bar {
            margin-top: 20px;
        }

        .firstLine>div {
            margin-bottom: 25px;
        }

        .firstLine>div.carret {
            width: 50%;
            margin-bottom: 25px;

            .medium_container {
                height: 310px;
            }
        }

        .firstLine .line1_col2 {
            width: 33%;
            display: flex;
            margin-bottom: 24px;
            justify-content: center;

            .medium_container {
                width: 100%;
                max-width: 100%;
                height: 310px;
            }
        }

        .firstLine .col {
            display: flex;
            flex-direction: column;

            .componentAbsoluteNumber {
                margin-bottom: 24px;

            }
        }
    }

    .graphesChallenges {
        .challengePie {
            min-width: 37%;

        }

        .challengePie>div {
            min-width: 100%
        }
    }

    .ProgressScreenLock {
        @media only screen and (max-width: 950px) {
            .graphes {
                .firstLine {
                    justify-content: space-between;

                    .line1_col2 {
                        margin-right: 0;
                        width: 40% !important;
                    }

                    div.carret {
                        width: 100% !important;
                    }

                    .smallStats {
                        width: 50%;

                        .componentAbsoluteNumber {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}