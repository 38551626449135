.ProgressScreenHub .graphes {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.ProgressScreenHub .noRadar > div {
  display: block;
}
.ProgressScreenHub .noRadar {
  display: flex;
  margin-bottom: 30px;
}
.ProgressScreenHub .noRadar > div > div {
  width: 240px;
  margin-right: 50px;
}
.ProgressScreenHub .graphes > .col1 > div:last-child {
  width: 400px;
  height: 400px;
}
.ProgressScreenHub .graphes > .col1 > div:first-child {
  margin-bottom: 50px;
}
.ProgressScreenHub .graphes > .col1 > div > div {
  max-width: 320px;
  height: max-content;
}
.ProgressScreenHub .graphes > .col1 > div > div .filterMenuWrapper {
  margin-top: 30px;
  margin-bottom: 10px;
}
.ProgressScreenHub .graphes > .col1 > div > div .circle_progress_bar {
  margin-bottom: 30px;
}
.ProgressScreenHub .graphes .col2 {
  margin-right: 0px;
  height: 700px;
}
.ProgressScreenHub .positionningGraphs .totalRessourceTimeStyle {
  flex-direction: column;
}
.ProgressScreenHub .positionningGraphs .case2 .col1 {
  flex-direction: column;
}

.ProgressScreenHub .positionningGraphs .totalRessourceTimeStyle .col1 {
  flex-direction: row;
}

.ProgressScreenHub .positionningGraphs .case2 > div {
  margin-right: 20px;
  display: flex;
}
.ProgressScreenHub .positionningGraphs .case2 .col2 > div {
  width: 250px;
  margin-right: 20px;
}
.ProgressScreenHub .positionningGraphs .case2 .col2 > div > .medium_container {
  max-width: 300px;
}
.ProgressScreenHub .positionningGraphs .case2 .col1 > div {
  margin-bottom: 20px;
}
.ProgressScreenHub .positionningGraphs .totalRessourceTimeStyle .col1 > div:last-child {
  margin-left: 30px;
}

.ProgressScreenHub .positionningGraphs .case2 .col2 {
  display: flex;
}

.filterMenu .dropMenuWrapper {
  width: 100%;
}
.ProgressScreenHub .autoPos {
  height: 100px;
}
.ProgressScreenHub .stat_header .stat_header_title_container {
  width: 76%;
}

/* filter menu fix */
::-webkit-scrollbar {
  background-color: '#fff';
}

::-webkit-scrollbar {
  width: 8px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: rgb(209, 209, 209);
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.2);
}
/* fix absolute number */
.ProgressScreenHub .card_absolute_text_container {
  word-break: normal !important;
}
.ProgressScreenHub .small_container {
  min-width: 200px;
}
.ProgressScreenHub .positionningGraphs > section {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.ProgressScreenHub .positionningGraphs > section > .graphWrapper {
  margin-right: 20px;
  margin-bottom: 20px;
}

.ProgressScreenHub .smallGraphs {
  display: flex;
}
.ProgressScreenHub .smallGraphs > div {
  margin-right: 20px;
  margin-bottom: 20px;
}
.ProgressScreenHub .secondSection {
  margin-top: 60px;
}
@media only screen and (max-width: 1175px) {
  .ProgressScreenHub .graphes > .col1 {
    margin-right: 20px;
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    justify-content: center;
    margin-right: 0;
    flex-wrap: wrap;
  }
  .ProgressScreenHub .graphes > .col2 {
    width: 100%;
  }
  .ProgressScreenHub .graphes > .col2 > div {
    margin: 0 auto;
  }
  .ProgressScreenHub .graphes > .col1 > div {
    display: flex;
  }

  .ProgressScreenHub .graphes > .col1 > div > div {
    margin-bottom: 30px;
    margin-right: 50px;
    min-width: 240px;
  }
}
@media only screen and (max-width: 910px) {
  .ProgressScreenHub .positionningGraphs .componentAbsoluteNumber {
    width: 250px;
  }
  .ProgressScreenHub .positionningGraphs .case2 > div {
    display: flex;
  }
  .ProgressScreenHub .positionningGraphs .case2 .col1 > div {
    margin-right: 20px;
  }
}

@media only screen and (max-width: 850px) {
  .ProgressScreenHub .smallGraphs {
    flex-wrap: wrap;
  }
  .ProgressScreenHub .graphes .col1 {
    justify-content: center;
    margin-right: 0;
  }
  /* .ProgressScreenHub .graphes > .col1 > div {
    width: 400px;
    height: 400px;
  } */

  .ProgressScreenHub .graphes > .col1 > div > div {
    margin-right: 10px;
    margin-left: 10px;
  }
  .ProgressScreenHub .positionningGraphs .small_container {
    min-width: auto;
    max-width: 250px;
  }
  .ProgressScreenHub .graphes > .col1 > div {
    width: 96%;
  }
  .ProgressScreenHub .graphes > .col1 > div:last-child {
    width: 96%;
  }
  .ProgressScreenHub .graphes > .col1 > div > div {
    max-width: 90%;
    min-width: 85%;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 730px) {
  .ProgressScreenHub .graphes .col2 {
    height: max-content;
  }
}

@media only screen and (max-width: 570px) {
  .ProgressScreenHub .TitleContainer h2 {
    margin: 0 auto;
  }

  .ProgressScreenHub .noRadar {
    display: flex;
    flex-direction: column;
  }
  .ProgressScreenHub .noRadar .smallGraphs .small_container {
    max-width: 80%;
    margin: auto;
    min-width: auto;
  }
  .ProgressScreenHub .noRadar > div > div {
    width: 100%;
  }
  .ProgressScreenHub .noRadar .medium_container {
    max-width: 80%;
    margin: auto;
  }

  .smallGraphs {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .ProgressScreenHub .smallGraphs .small_container {
    max-width: 80%;
    margin: auto;
    min-width: auto;
  }
  .ProgressScreenHub .smallGraphs > div {
    margin-right: 0;
  }
  .ProgressScreenHub .positionningGraphs .case1 .containerPercentageComp {
    width: 90%;
  }
  .ProgressScreenHub .positionningGraphs .small_container {
    min-width: auto;
    max-width: 100%;
  }
  .ProgressScreenHub .positionningGraphs .case1 > div {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-right: 0;
  }
  .ProgressScreenHub .positionningGraphs .case2 > div {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-right: 0;
  }
  .ProgressScreenHub .positionningGraphs .componentAbsoluteNumber {
    width: 90%;
  }
  .ProgressScreenHub .positionningGraphs .case2 .col2 > div {
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
  .ProgressScreenHub .positionningGraphs .case2 .col2 > div > div {
    max-width: 100%;
  }

  .ProgressScreenHub .positionningGraphs .case2 .col1 > div {
    margin: auto;
    margin-bottom: 20px;
  }

  .ProgressScreenHub .graphes > .col1 > div {
    width: 96%;
  }
  .ProgressScreenHub .graphes > .col1 > div > div {
    max-width: 90%;
    min-width: 85%;
  }
}
