 .EngagementScreenLock {
     .graphes_section2 {
         .firstLine {
             &>div {
                 width: 23.2%;
             }
         }

         .secondLineChallenges {
             justify-content: flex-start;

             &>div {
                 width: 23.2%;
             }
         }
     }
 }

 .riseUpMode {
     .EngagementScreenLock {
         .smallStats {
             gap: 24px;
         }

         .graphes .firstLine>div {
             margin: 0;
         }

         .medium_container {
             height: 332px;
             width: 420px;
             max-height: 332px;
             max-width: 420px;
             box-sizing: border-box;
         }

         .secondLine {
             margin-top: 24px;

             .stat_header {
                 height: 56px;

                 .stat_header_title_container {
                     margin: 0;
                     margin-left: 8px;
                 }
             }

             .circle_progress_bar {
                 margin-top: 35px;
             }

             .engagementPercentage:nth-child(2) {
                 .stat_header_title_container {
                     margin-left: 6px
                 }
             }

             .engagementPercentage {
                 width: 32%;
                 display: flex;
                 align-items: center;
                 justify-content: center;
             }
         }

         @media only screen and (max-width: 1040px) {
             .smallStats {
                 gap: 24px;
                 gap: 15px
             }

             .graphes {
                 .small_container {
                     max-width: 100%;
                 }

                 .firstLine .componentAbsoluteNumber:nth-child(odd) {
                     margin-right: 0%
                 }

                 .secondLine {
                     margin-right: 4%;
                     justify-content: flex-start;
                     gap: 20px;

                     .engagementPercentage {
                         margin-right: 0;
                         width: 100%;

                         .medium_container {
                             height: 100%;
                             width: 100%;
                             max-height: 100%;
                             max-width: 100%;

                         }
                     }
                 }
             }
         }
     }
 }